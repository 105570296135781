import { Link } from 'gatsby';
import React from 'react';
import FaqText from './otherlanguage/FaqText';
import PrivacyPolicyText from './otherlanguage/PrivacypolicyText';
import TermsText from './otherlanguage/TermsText';

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <p>&copy; <a className='footertext' href='https://yurikanagawa.com/'>Yuri Kanagawa.</a> All Rights Reserved.</p>
        <ul className="list-inline">
          <li className="list-inline-item" >
            <Link to="/privacy_policy" className='footertext'>
              <PrivacyPolicyText/>
            </Link>
            {/* <a href="/privacy_policy" className="langCng" lang="en">Privacy</a>
            <a href="/privacy_policy" className="langCng" lang="ja">プライバシー</a>
            <a href="/privacy_policy" className="langCng" lang="py">Конфиденциальность</a>
            <a href="/privacy_policy" className="langCng" lang="fr">intimité</a>
            <a href="/privacy_policy" className="langCng" lang="de">Privatsphäre</a>
            <a href="/privacy_policy" className="langCng" lang="it">privacy</a>
            <a href="/privacy_policy" className="langCng" lang="ch">隐私</a>
            <a href="/privacy_policy" className="langCng" lang="ko">개인 정보 보호</a>
            <a href="/privacy_policy" className="langCng" lang="su">yksityisyyttä</a>
            <a href="/privacy_policy" className="langCng" lang="es">intimidad</a>
            <a href="/privacy_policy" className="langCng" lang="is">mahremiyet</a>
            <a href="/privacy_policy" className="langCng" lang="ba">privasi</a>
            <a href="/privacy_policy" className="langCng" lang="ta">தனியுரிமை</a> */}
          </li>
          <li className="list-inline-item">
          <Link to="/terms" className='footertext'>
              <TermsText/>
            </Link>
            {/* <a href="/terms" className="langCng" lang="en">Terms</a>
            <a href="/terms" className="langCng" lang="ja">規約</a>
            <a href="/terms" className="langCng" lang="py">Условия</a>
            <a href="/terms" className="langCng" lang="fr">termes</a>
            <a href="/terms" className="langCng" lang="de">Bedingungen</a>
            <a href="/terms" className="langCng" lang="it">Termini</a>
            <a href="/terms" className="langCng" lang="ch">条款</a>
            <a href="/terms" className="langCng" lang="ko">약관</a>
            <a href="/terms" className="langCng" lang="su">Ehdot</a>
            <a href="/terms" className="langCng" lang="es">Condiciones</a>
            <a href="/terms" className="langCng" lang="is">Şartlar</a>
            <a href="/terms" className="langCng" lang="ba">Syarat</a>
            <a href="/terms" className="langCng" lang="ta">விதிமுறை</a> */}
          </li>
          <li className="list-inline-item">
            {/* <a href="/faq" className="langCng" lang="en">FAQ</a>
            <a href="/faq" className="langCng" lang="ja">質問</a>
            <a href="/faq" className="langCng" lang="py">вопрос</a>
            <a href="/faq" className="langCng" lang="fr">FAQ</a>
            <a href="/faq" className="langCng" lang="de">FAQ</a>
            <a href="/faq" className="langCng" lang="it">FAQ</a>
            <a href="/faq" className="langCng" lang="ch">常问问题</a>
            <a href="/faq" className="langCng" lang="ko">질문</a>
            <a href="/faq" className="langCng" lang="su">FAQ</a>
            <a href="/faq" className="langCng" lang="es">pregunta</a>
            <a href="/faq" className="langCng" lang="is">SSS</a>
            <a href="/faq" className="langCng" lang="ba">soalan</a>
            <a href="/faq" className="langCng" lang="ta">கேள்வி</a> */}
            <Link to="/faq" className='footertext'>
              <FaqText/>
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}
