import React from 'react';

export default function PrivacyPolicyText() {
    return (
        <div >
          <div className = "langCng" lang="en">PrivacyPolicy</div>
          <div className = "langCng" lang="ru">Политика конфиденциальности</div>
          <div className = "langCng" lang="fr">Politique de confidentialité</div>
          <div className = "langCng" lang="de">Datenschutz-Bestimmungen</div>
          <div className = "langCng" lang="it">Politica sulla riservatezza</div>
          <div className = "langCng" lang="fi">Tietosuojakäytäntö</div>
          <div className = "langCng" lang="es">Política de privacidad</div>
          <div className = "langCng" lang="tr">Gizlilik Politikası</div>
          <div className = "langCng" lang="ms">Dasar Privasi</div>
          <div className = "langCng" lang="ta">தனியுரிமைக் கொள்கை</div>
          <div className = "langCng" lang="id">Rahasia pribadi</div>
          <div className = "langCng" lang="pt">Política de Privacidade</div>
          <div className = "langCng" lang="hi">गोपनीयता नीति</div>
          <div className = "langCng" lang="ar">سياسة الخصوصية</div>
          <div className = "langCng" lang="ja">プライバシーポリシー</div>
          <div className = "langCng" lang="zh">隐私政策</div>
          <div className = "langCng" lang="ko">개인 정보 정책</div>
        </div>
    );
  }