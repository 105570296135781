import React from 'react';

export default function TermsText() {
    return (
        <div >
          <div className = "langCng" lang="en">Terms</div>
          <div className = "langCng" lang="ru">Условия</div>
          <div className = "langCng" lang="fr">termes</div>
          <div className = "langCng" lang="de">Bedingungen</div>
          <div className = "langCng" lang="it">Termini</div>
          <div className = "langCng" lang="fi">Ehdot</div>
          <div className = "langCng" lang="es">CONTACTO</div>
          <div className = "langCng" lang="tr">şartlar</div>
          <div className = "langCng" lang="ms">Syarat</div>
          <div className = "langCng" lang="ta">விதிமுறை</div>
          <div className = "langCng" lang="id">Ketentuan</div>
          <div className = "langCng" lang="pt">Termos</div>
          <div className = "langCng" lang="hi">मामले</div>
          <div className = "langCng" lang="ar">شروط</div>
          <div className = "langCng" lang="ja">利用規約</div>
          <div className = "langCng" lang="zh">服务条款</div>
          <div className = "langCng" lang="ko">이용약관</div>
        </div>
    );
  }