import React from 'react';

export default function FaqText() {
    return (
        <div >
          <div className = "langCng" lang="en">FAQ</div>
          <div className = "langCng" lang="ru">Вопросы-Ответы</div>
          <div className = "langCng" lang="fr">FAQ</div>
          <div className = "langCng" lang="de">FAQ</div>
          <div className = "langCng" lang="it">FAQ</div>
          <div className = "langCng" lang="fi">FAQ</div>
          <div className = "langCng" lang="es">Preguntas más frecuentes</div>
          <div className = "langCng" lang="tr">SSS</div>
          <div className = "langCng" lang="ms">Soalan Lazim</div>
          <div className = "langCng" lang="ta">அடிக்கடி கேட்கப்படும் கேள்விகள்</div>
          <div className = "langCng" lang="id">FAQ</div>
          <div className = "langCng" lang="pt">Perguntas frequentes</div>
          <div className = "langCng" lang="hi">सामान्य प्रश्न</div>
          <div className = "langCng" lang="ar">التعليمات</div>
          <div className = "langCng" lang="ja">よくある質問</div>
          <div className = "langCng" lang="zh">常问问题</div>
          <div className = "langCng" lang="ko">자주하는 질문</div>
        </div>
    );
  }